import React from "react";
import chrisPhoto from "../christian-about.jpg";
import karaPhoto from "../kara-about.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  return (
    <div className="about">
      <div className="container">
        <div className="about-wrapper">
          <h1>about us</h1>
          <div className="about-info pb-5">
            <p>
              AstroWeb Development is a small website development company that
              builds highly-customized, premium websites. We specialize in
              helping small businesses create and transform their websites in
              order to increase their online traffic and revenue. <br /> <br />{" "}
              Our purpose is to connect each business with their target customer
              through the world’s primary method of information and digital
              communication: the internet. With this purpose in mind, each
              website is crafted to the specifications of our clients to design
              a unique atmosphere that resonates with each user.
            </p>
          </div>
          <div className="row py-4">
            <div className="col-sm-6 col-m-6 christian-col">
              <img
                className="py-1"
                src={chrisPhoto}
                alt="Christian Holmberg Founder of AstroWeb Development"
              />
              <h2 className="py-1">Christian Holmberg</h2>
              <h3>Founder, CTO </h3>
              <p className="py-3">
                Christian Holmberg is an engineering student in the Viterbi
                School of Engineering at the University of Southern California.
                He is naturally solution-oriented, technical, and quantitatively
                skilled. Beyond extensive web development training, Christian’s
                background also includes rocketry development in USC’s Rocket
                Propulsion Lab projects as well as other team leadership roles.
                With this, Christian’s web development technical training and
                expertise allows him to craft completely customized,
                high-quality websites that meet the tailored needs of each
                specific client. <br />
                <br />
                At AstroWeb Development, Christian leads all web development.
              </p>
              <a
                className="social-icon"
                href="https://www.linkedin.com/in/christianholmberg/"
                target="_blank"
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={faLinkedin}
                  size="3x"
                  color="#5285B1"
                />
              </a>
              <a
                className="social-icon"
                href="https://github.com/christianoholmberg"
                target="_blank"
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={faGithubSquare}
                  size="3x"
                  color="#5285B1"
                />
              </a>
              <a
                className="social-icon"
                href="https://www.christianholmberg.com/"
                target="_blank"
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={faDesktop}
                  size="3x"
                  color="#5285B1"
                />
              </a>
            </div>
            {/* KARA COL */}
            <div className="col-sm-6 col-m-6 kara-col">
              <img
                className="py-1"
                src={karaPhoto}
                alt="Kara Holmberg Founder of AstroWeb Development"
              />
              <h2 className="py-1">Kara Holmberg</h2>
              <h3>Founder, CEO </h3>
              <p className="py-3">
                Kara Holmberg is a business student in the Marshall School of
                Business at the University of Southern California. She is
                entrepreneurial and execution-driven at heart, with a background
                filled with leadership roles and the Co-Founder of a small
                fashion eCommerce business called “Leudic”. Some of her
                leadership experiences include serving on the Executive Board as
                Director of Outreach for USC’s Marshall Women’s Leadership Board
                and Georgia State President of the Georgia Association of
                Student Councils. Kara’s ambition, persistence, and
                people-driven mindset allows her to meaningfully connect with
                peers, clients, and the project in order to efficiently and
                successfully achieve their vision.
                <br />
                <br />
                At AstroWeb Development, Kara leads project management,
                communication, finances, and client relations.
              </p>
              <a
                className="social-icon"
                href="https://linktr.ee/leudic"
                target="_blank"
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={faDesktop}
                  size="3x"
                  color="#5285B1"
                />
                <a
                  className="social-icon"
                  href="https://www.linkedin.com/in/karaholmberg/"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    className="icon"
                    icon={faLinkedin}
                    size="3x"
                    color="#5285B1"
                  />
                </a>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
