import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-2 col-sm-6 align-items-center pt-2 pb-3">
            <div className="row">
              <div className="col">
                <NavLink className="navbar-brand" to="/">
                  Home
                </NavLink>
                <br />
                <NavLink className="navbar-brand" to="/About">
                  About Us
                </NavLink>
                <br />
              </div>
              <div className="col">
                <NavLink className="navbar-brand" to="/Services">
                  Services
                </NavLink>
                <br />
                <NavLink className="navbar-brand" to="/Contacts">
                  Contact Us
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
            <div className="d-flex pt-2 justify-content-center">
              <p>business@astrowebdev.com</p>
              {/*  */}
            </div>
            <p className="pt-2 text-center">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;AstroWeb Development || All Rights
              Reserved
            </p>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6 align-items-center pt-2 pb-3 align-items-center">
            <div className="d-flex justify-content-center">
              <a
                className="social-icon"
                href="https://www.linkedin.com/company/astrowebdevelopment"
                target="_blank"
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={faLinkedin}
                  size="3x"
                  color="#5285b1"
                />
              </a>
              {/*  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
