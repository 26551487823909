import React from "react";
import Typed from "react-typed";
import { Link } from "react-router-dom";

import coverphoto from "../homebackground.jpg";
import covervid from "../video-1.mp4";

const Header = () => {
  return (
    <div id="Home" className="header-wrapper">
      <div className="main-info">
        <img
          className="homephoto"
          src={coverphoto}
          alt="AstroWeb Development Background"
        />
        <h1>
          Where The Limits of Your Website Are <br />
          <span className="home-h1-span">Out of This World</span>
        </h1>
        <Typed
          className="typed-text"
          strings={[
            "Web Design",
            "Web Development",
            "Website Hosting",
            "User Experience",
            "Search Engine Optimization",
          ]}
          typeSpeed={100}
          backSpeed={60}
          loop
        />
        <Link
          smooth={true}
          to="Services"
          offset={-90}
          href="#"
          className="btn-main-offer"
        >
          Learn More
        </Link>
      </div>
    </div>
  );
};

export default Header;
