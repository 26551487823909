import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faFileCode,
  faWifi,
  faUsers,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Services = () => {
  return (
    <div className="services">
      <div className="container">
        <div className="services-wrapper">
          <h1>services</h1>
          <div className="row first-row">
            <div className="col-sm-4 col-m-4 d-flex justify-content-center service-col">
              <div className="box">
                <FontAwesomeIcon className="icon" icon={faFileCode} size="3x" />
                <h3>Web Development</h3>
                <p>
                  We build all of our websites with new, proven technologies. We
                  are constantly improving and updating everything we use in
                  order to have the most modern and up-to-date website.
                </p>
              </div>
            </div>
            <div className="col-sm-4 col-m-4 d-flex justify-content-center service-col">
              <div className="box">
                <FontAwesomeIcon className="icon" icon={faDesktop} size="3x" />
                <h3>Web Design</h3>
                <p>
                  We design each project from scratch using custom-created
                  styles, layouts, and color combinations. We work with our
                  clients to create a website that captures the feel and
                  aesthetic that they desire.
                </p>
              </div>
            </div>
            <div className="col-sm-4 col-m-4 d-flex justify-content-center service-col">
              <div className="box">
                <FontAwesomeIcon className="icon" icon={faWifi} size="3x" />
                <h3>Web Hosting</h3>
                <p>
                  We host all of our clients websites at a competitive price
                  point. We offer 24/7 support for content editing as well as
                  99% uptime for our websites.
                </p>
              </div>
            </div>
          </div>
          <div className="row second-row">
            <div className="col-sm-6 col-m-6 d-flex justify-content-center service-col">
              <div className="box">
                <FontAwesomeIcon className="icon" icon={faUsers} size="3x" />
                <h3>User Experience</h3>
                <p>
                  We prioritize the user experience when creating our websites.
                  Having a comfortable, intuitive and dynamic website that works
                  across a range of devices is key to success and credibility.
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-m-6 d-flex justify-content-center service-col">
              <div className="box">
                <FontAwesomeIcon className="icon" icon={faSearch} size="3x" />
                <h3>Search Engine Optimization</h3>
                <p>
                  We will include the back-end, technical elements when coding
                  your website that help improve the likelihood of our clients'
                  websites appearing on search engines.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
